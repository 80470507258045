<template>
    <div class="riskmodel-container">
        <div class="header-container">
            <ul id="project-layers" class="tocList layers-container">
                <li>
                    <layer-selection
                        :zoomLevel="zoomLevel"
                        treeFilter="Risk Model"
                        selectDefault="PoF"
                    />
                </li>
            </ul>
        </div>

        <div class="content-container" v-if="withRiskModel">
            <div class="d-flex">
                <h5 class="trans-sm">Projection Year</h5>
                <form class="grey-color">
                    <input
                        type="range"
                        v-model.number="projectionYear"
                        min="0"
                        step="5"
                        max="100"
                    />
                </form>
                <p>
                    <input
                        type="number"
                        class="input-value"
                        display="inline-block"
                        min="minProjection"
                        max="maxProjection"
                        maxlength="4"
                        size="2.5ch"
                        padding-top="5px"
                        v-model.lazy="projectedYear"
                    />
                </p>
            </div>

            <div class="tool-btns">
                <b-button
                    class="tool-btn"
                    v-bind:class="
                        activeMapTool == 'identifyPipe'
                            ? 'btn-selected'
                            : 'btn-unselected'
                    "
                    @click="identifyPipe"
                    :disabled="hasDrawnPoint"
                >
                    Select Single
                </b-button>
                <b-button
                    class="tool-btn"
                    v-bind:class="
                        activeMapTool == 'drawPolygon'
                            ? 'btn-selected'
                            : 'btn-unselected'
                    "
                    @click="drawPolygon()"
                    :disabled="hasDrawnPolygon"
                >
                    Select Multiple
                </b-button>
            </div>

            <div class="d-flex justify-content-end cancel-btns">
                <button
                    v-if="activeMapTool != ''"
                    class="btn btn-clear btn-buffer"
                    @click="cancelMark()"
                >
                    Cancel</button
                ><br />
                <button
                    v-if="activeMapTool === '' && hasDrawnPolygon"
                    class="btn btn-clear btn-buffer"
                    @click="loadPipeIntersection()"
                >
                    Edit</button
                ><br />
                <button
                    v-if="activeMapTool === '' && hasDrawnPoint"
                    class="btn btn-clear btn-buffer"
                    @click="editPointIntersection"
                >
                    Edit</button
                ><br />
                <button
                    class="btn btn-clear btn-buffer"
                    @click="clearGeometry()"
                >
                    Clear</button
                ><br />
            </div>
        </div>

        <div class="footer-container">
            <div>
                <b-form-file
                    accept=".zip"
                    v-model="file"
                    class="upload-formlabel"
                    id="newRiskModelUpload"
                    ref="newRiskModelUpload"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="riskModelFileValidation()"
                ></b-form-file>
            </div>
            <div>
                <b-button
                    class="buttonSelect map-btn m-1"
                    @click="uploadRiskModel(file)"
                    :disabled="withRiskModel"
                >
                    Upload
                </b-button>
                <b-button
                    class="buttonSelect map-btn m-1"
                    @click="updateRiskModel()"
                    :disabled="disableNoUpdateComputed"
                >
                    Generate Model
                </b-button>
            </div>
            <div>
                <b-button
                    class="buttonSelect map-btn m-1"
                    @click="downloadRiskModel()"
                >
                    Download
                </b-button>
                <b-button
                    class="buttonSelect map-btn m-1"
                    @click="resetRiskModel()"
                    :disabled="isOriginalShape()"
                >
                    Discard Changes
                </b-button>
            </div>
        </div>

        <!-- Select Multiple -->
        <b-modal
            v-bind:no-close-on-backdrop="false"
            id="risk-modal"
            ref="risk-modal"
            content-class="modal_style"
            hide-header
            hide-footer
            size="lg"
        >
            <div class="riskgrid-container">
                <div id="Grid"></div>
                <ejs-grid
                    id="grid"
                    ref="grid"
                    :dataSource="dataPipes"
                    :editSettings="editOptions"
                    :cellSaved="onCellSaved"
                    width="4600"
                    :allowResizing="true"
                    :actionBegin="actionBegin"
                    :toolbar="toolbar"
                    :dataBound="dataBound"
                >
                    <e-columns>
                        <e-column
                            field="GHDAssetId"
                            width="100"
                            textAlign="Center"
                            headerText="GHD Asset Id"
                            title="GHD Asset Id"
                            :isPrimaryKey="true"
                        ></e-column>
                        <e-column
                            field="Pipe Type"
                            width="50"
                            textAlign="Center"
                            headerText="Pipe Type"
                            :allowEditing="false"
                        ></e-column>
                        <e-column
                            field="Modeled Diameter"
                            width="50"
                            textAlign="Center"
                            headerText="Diameter"
                        ></e-column>
                        <e-column
                            field="Install Year (GIS)"
                            width="60"
                            textAlign="Center"
                            headerText="Install Year"
                            editType="numericedit"
                        ></e-column>
                        <e-column
                            field="Modeled Material"
                            width="60"
                            headerText="Material"
                            editType="dropdownedit"
                            :edit="materialParams"
                        ></e-column>
                        <e-column
                            field="Material (GIS)"
                            width="50"
                            textAlign="Center"
                            headerText="Material (GIS)"
                            :allowEditing="false"
                        ></e-column>
                        <e-column
                            field="CCTV Material"
                            width="50"
                            textAlign="Center"
                            headerText="CCTV Material"
                            :allowEditing="false"
                        ></e-column>
                        <e-column
                            field="Depth"
                            width="50"
                            textAlign="Center"
                            headerText="Depth"
                            :allowEditing="false"
                        ></e-column>
                        <e-column
                            field="CCTV Laid Date"
                            width="50"
                            textAlign="Center"
                            headerText="CCTV Laid Date"
                            :allowEditing="false"
                        ></e-column>
                        <e-column
                            field="Material Date"
                            width="50"
                            textAlign="Center"
                            headerText="Material Date"
                            :allowEditing="false"
                        ></e-column>
                        <e-column
                            field="Modeled Date"
                            width="50"
                            textAlign="Center"
                            headerText="Modeled Date"
                            :allowEditing="false"
                        ></e-column>
                        <e-column
                            field="Current Age"
                            width="50"
                            textAlign="Center"
                            headerText="Current Age"
                            :allowEditing="false"
                        ></e-column>
                        <e-column
                            field="Diameter (in) (GIS)"
                            width="50"
                            textAlign="Center"
                            headerText="Diameter (in) (GIS)"
                            :allowEditing="false"
                        ></e-column>
                        <e-column
                            field="CCTV Diameter"
                            width="50"
                            textAlign="Center"
                            headerText="CCTV Diameter"
                            :allowEditing="false"
                        ></e-column>
                        <e-column
                            field="Length (ft)"
                            width="50"
                            textAlign="Center"
                            headerText="Length (ft)"
                            :allowEditing="false"
                        ></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </b-modal>

        <!-- Select Single -->
        <b-modal
            id="modal-edit"
            centered
            hide-footer
            title="Edit Pipe Attributes"
        >
            <b-row class="my-1">
                <b-col sm="5">
                    <label>Diameter</label>
                </b-col>
                <b-col sm="7" class="px-0">
                    <b-form-input
                        class="rowStyle"
                        v-model.number="riskAttributes['Modeled Diameter']"
                    >
                    </b-form-input>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col sm="5">
                    <label>Install Year</label>
                </b-col>
                <b-col sm="7" class="px-0">
                    <b-form-input
                        class="rowStyle"
                        v-model.number="riskAttributes['Install Year (GIS)']"
                    >
                    </b-form-input>
                </b-col>
            </b-row>
            <b-row class="my-1 d-flex justify-content-center">
                <b-col sm="5">
                    <label for="textarea">Material</label>
                </b-col>
                <b-col sm="7" class="px-0">
                    <!-- 'Modeled Material' -->
                    <b-form-select
                        class="mb-1"
                        v-model="riskAttributes['Modeled Material']"
                    >
                        <option
                            v-for="ele in riskDropdownData[
                                riskAttributes['Pipe Type']
                            ]"
                            :key="ele"
                        >
                            {{ ele }}
                        </option>
                    </b-form-select>
                </b-col>
            </b-row>
            <div class="modal-displayrow">
                <div
                    class="row"
                    v-for="(value, name, index) in riskAttributes"
                    :key="index"
                    v-show="attributeDisplayOnly(name)"
                >
                    <b-col sm="6">
                        <label>{{ name }}</label>
                    </b-col>
                    <b-col sm="6" class="px-0">{{
                        !isNaN(value) && !Number.isInteger(value)
                            ? Number(value).toFixed(3)
                            : value
                    }}</b-col>
                </div>
            </div>

            <b-row class="d-flex justify-content-end">
                <b-button
                    class="btn btn-light"
                    @click="$bvModal.hide('modal-edit')"
                >
                    Cancel
                </b-button>
                <b-button class="btn btn-secondary" @click="updateAttribute">
                    Save
                </b-button>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import LayerSelection from './LayerSelection.vue';
import { dreamsService } from '../../services/dreams.service';
import { dreamsFunctions } from '../../utilities/dreamsFunctions';
import { drawingMixin } from '../../mixins/drawing.mixin';
import { spatialFunctions } from '../../utilities/spatialFunctions';

import Vue from 'vue';
import {
    GridPlugin,
    Edit,
    Grid,
    Page,
    Sort,
    Group,
    Toolbar,
    Resize,
} from '@syncfusion/ej2-vue-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { getComponent } from '@syncfusion/ej2-base';
Vue.use(GridPlugin);
var grid;
let materialElem, materialObj, tempQuery;
let fmDropdown = ['DIP', 'HDPE', 'PVC', 'STL', 'UNK', 'VCP'];
let gmDropdown = ['AC', 'DIP', 'HDPE', 'PVC', 'UNK', 'VCP'];

export default {
    name: 'RiskModel',
    components: {
        LayerSelection,
    },
    provide: {
        grid: [Edit, Sort, Group, Page, Toolbar, Resize],
    },
    mixins: [drawingMixin],
    data() {
        return {
            file: null,
            zoomLevel: 5,
            riskSelected: 'Time Condition Score',
            currentGeoJson: null,
            projectionYear: 0,
            pofOptions: ['Very low', 'Low', 'Moderate', 'High', 'Very high'],
            cofOptions: [
                'Negligible',
                'Low',
                'Moderate',
                'High',
                'Catastrophic',
            ],
            dataPipes: [],
            editOptions: {
                allowAdding: false,
                allowEditing: true,
                allowDeleting: false,
                mode: 'Batch',
            },
            toolbar: ['Edit', 'Update', 'Cancel'],
            numericParams: { params: { format: 'C2' } },
            batchEdit: false,
            disableGenerateYear: true,
            materialParams: {
                create: () => {
                    materialElem = document.createElement('input');
                    return materialElem;
                },
                read: () => {
                    return materialObj.text;
                },
                destroy: () => {
                    materialObj.destroy();
                },
                write: (args) => {
                    grid = new getComponent('grid', 'grid');
                    var rowData = args.rowData;
                    var dropdownSource = rowData['Pipe Type'];
                    if (dropdownSource == 'FM') {
                        materialObj = new DropDownList({
                            dataSource: fmDropdown,
                            query: tempQuery,
                            created: () => {
                                materialObj.showPopup();
                            },
                        });
                    } else {
                        materialObj = new DropDownList({
                            dataSource: gmDropdown,
                            query: tempQuery,
                            created: () => {
                                materialObj.showPopup();
                            },
                        });
                    }

                    materialObj.appendTo(materialElem);
                },
            },
            editableAttributes: [
                'Modeled Material',
                'Install Year (GIS)',
                'Modeled Diameter',
            ],
            hiddenAttributes: [
                'user_id',
                'proj_id',
                'version',
                'projected_year',
                'geometry',
            ],
            riskDropdownData: {
                FM: ['DIP', 'HDPE', 'PVC', 'STL', 'UNK', 'VCP'],
                GM: ['AC', 'DIP', 'HDPE', 'PVC', 'UNK', 'VCP'],
            },
            setGridColumn: true,
            withRiskModel: false,
            intervalQueue: [null, null, null, null, null, null],
        };
    },
    methods: {
        dataBound: function() {
            this.$refs.grid.autoFitColumns(['GHDAssetId', 'Pipe Type']);
        },
        actionBegin(args) {
            if (args.requestType === 'beginEdit') {
                args.cancel = true;
            }
            if (args.requestType === 'save') {
                args.cancel = false;
            }
        },
        actionComplete(args) {
            // Set initial Focus
            if (args.requestType === 'beginEdit') {
                args.form.elements.namedItem('Modeled Diameter').focus();
            }
        },
        ...mapMutations('oneMap', ['addIdBuffer']),
        ...mapActions('oneMap', {
            clearWhiteboardGeoms: 'clearWhiteboardGeoms',
            setMapTool: 'setMapTool',
            clearMapTool: 'clearMapTool',
            clearIdBuffer: 'clearIdBuffer',
            clearHighlight: 'clearHighlightGeom',
        }),

        ...mapMutations('common', ['setToastMessage']),
        ...mapActions('dreams', [
            'setModel',
            'setRiskModel',
            'clearRiskAttribute',
            'setColorRange',
            'clearColorRange',
            'addRiskAttribute',
            'clearDreams',
            'setVersion',
            'setShowModel',
            'setRefreshMap',
        ]),
        riskModelFileValidation() {
            const queueService = 'Upload zipped shape file';
            var fileInput = document.getElementById('newRiskModelUpload');
            var filePath = fileInput.value;
            var allowedExtensions = /\.zip$/i;
            var extensionCheck = allowedExtensions.exec(filePath);

            if (!extensionCheck) {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'error',
                    queueMessage: 'Invalid file type',
                    downloadLink: '',
                    visible: true,
                });
                fileInput.value = '';
                return false;
            }
        },
        uploadRiskModel(file) {
            const queueService = 'Upload zipped shape file';

            try {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'start',
                    queueMessage: 'started.',
                    downloadLink: '',
                    visible: true,
                });

                let data = new FormData();
                data.append('risk_zip', file);
                data.append('user_id', this.UserId);
                data.append('proj_id', this.ProjectId);

                dreamsService.UploadRiskModel(data).then((init_response) => {
                    setTimeout(() => {
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'progress',
                            queueMessage: 'in progress!',
                            downloadLink: '',
                            visible: true,
                        });
                    }, '1000');

                    const taskId = init_response.data.task_id;
                    this.startStatusCheckInterval(taskId, queueService, 0); //0:UploadRiskModel
                });
            } catch (error) {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'error',
                    queueMessage: error.request,
                    downloadLink: '',
                    visible: true,
                });
            }
        },
        resetRiskModel() {
            dreamsFunctions.clearMap();
            this.clearRiskAttribute();
            this.currentGeoJson = null;
            this.projectionYear = 0;
            const queueService = 'Risk Modeling Discard Changes';

            try {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'start',
                    queueMessage: 'started.',
                    downloadLink: '',
                    visible: true,
                });

                let data = { user_id: this.UserId, project_id: this.ProjectId };
                dreamsService.ResetRiskModel(data).then((init_response) => {
                    setTimeout(() => {
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'progress',
                            queueMessage: 'in progress!',
                            downloadLink: '',
                            visible: true,
                        });
                    }, '1000');

                    const taskId = init_response.data.task_id;
                    this.startStatusCheckInterval(taskId, queueService, 1); //1:ResetRiskModel
                });
            } catch (error) {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'error',
                    queueMessage: error.request,
                    downloadLink: '',
                    visible: true,
                });
            }
        },
        async startStatusCheckInterval(
            taskId,
            queueService,
            queueId,
            showSuccess = true
        ) {
            this.intervalQueue[queueId] = setInterval(async () => {
                try {
                    const status_response = await dreamsService.TaskStatus(
                        taskId
                    );
                    const taskStatus = status_response.data.task_status;
                    let taskResult = status_response.data.task_result;
                    if (taskStatus == 'SUCCESS') {
                        taskResult = JSON.parse(taskResult);
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'complete',
                            queueMessage: 'completed.',
                            downloadLink: '',
                            visible: showSuccess,
                        });
                        switch (queueService) {
                            case 'Map Intersection':
                            case 'Map Intersections':
                                this.dataPipes = taskResult.intersects;
                                break;
                            case 'Latest Version':
                                this.setVersion(
                                    taskResult.latest_version == null
                                        ? 1
                                        : taskResult.latest_version
                                );
                                this.setProjection(taskResult.projected_year);
                                this.withRiskModel = true;
                                break;
                        }
                        clearInterval(this.intervalQueue[queueId]);
                        if (queueId == 2) this.setRefreshMap(true);
                    } else if (taskStatus == 'FAILURE') {
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'error',
                            queueMessage: taskResult,
                            downloadLink: '',
                            visible: true,
                        });
                        clearInterval(this.intervalQueue[queueId]);
                    }
                } catch (error) {
                    this.setToastMessage({
                        queueService: queueService,
                        queueState: 'error',
                        queueMessage: error,
                        downloadLink: '',
                        visible: true,
                    });
                }
            }, 5000);
        },
        downloadRiskModel() {
            const queueService = 'Risk Modeling Download';

            try {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'start',
                    queueMessage: 'started.',
                    downloadLink: '',
                    visible: true,
                });
                dreamsService
                    .DownloadRiskModel(this.UserId, this.ProjectId)
                    .then((init_response) => {
                        setTimeout(() => {
                            this.setToastMessage({
                                queueService: queueService,
                                queueState: 'progress',
                                queueMessage: 'in progress!',
                                downloadLink: '',
                                visible: true,
                            });
                        }, '1000');

                        const taskId = init_response.data.task_id;
                        let statusIntervalID = setInterval(async () => {
                            const status_response = await dreamsService.TaskStatus(
                                taskId
                            );
                            const taskStatus = status_response.data.task_status;

                            if (taskStatus == 'SUCCESS') {
                                this.setToastMessage({
                                    queueService: queueService,
                                    queueState: 'complete',
                                    queueMessage: 'completed.',
                                    downloadLink: '',
                                    visible: true,
                                });
                                this.downloadFile(queueService);
                                clearInterval(statusIntervalID);
                            } else if (taskStatus == 'FAILURE') {
                                this.setToastMessage({
                                    queueService: queueService,
                                    queueState: 'error',
                                    queueMessage:
                                        status_response.data.task_result,
                                    downloadLink: '',
                                    visible: true,
                                });
                                clearInterval(statusIntervalID);
                            }
                        }, 5000);
                    });
            } catch (error) {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'error',
                    queueMessage: error.request,
                    downloadLink: '',
                    visible: true,
                });
            }
        },
        downloadFile(queueService) {
            try {
                dreamsService
                    .ExportShape(this.UserId, this.ProjectId)
                    .then((response) => {
                        let fileUrl = window.URL.createObjectURL(response.data);
                        let fileLink = document.createElement('a');
                        fileLink.href = fileUrl;
                        fileLink.setAttribute('download', 'risk-model.zip');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'complete',
                            queueMessage: 'completed.',
                            downloadLink: '',
                            visible: true,
                        });
                    });
            } catch (error) {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'error',
                    queueMessage: error.message,
                    downloadLink: '',
                    visible: true,
                });
            }
        },
        updateRiskModel() {
            const queueService = 'Risk Modeling Generate Model';
            try {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'start',
                    queueMessage: 'started.',
                    downloadLink: '',
                    visible: true,
                });
                let data = {
                    user_id: this.UserId,
                    project_id: this.ProjectId,
                    projected_year: this.projectedYear,
                    changeset: this.dataPipes,
                };

                dreamsService.UpdateRiskModel(data).then((init_response) => {
                    setTimeout(() => {
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'progress',
                            queueMessage: 'in progress!',
                            downloadLink: '',
                            visible: true,
                        });
                    }, '1000');

                    const taskId = init_response.data.task_id;
                    this.startStatusCheckInterval(taskId, queueService, 2); //2:UpdateRiskModel

                    this.clearRiskAttribute();
                    //TODO:refresh layers
                    this.disableGenerateYear = true;
                });
            } catch (error) {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'error',
                    queueMessage: error.message,
                    downloadLink: '',
                    visible: true,
                });
            }
        },
        cancelMark() {
            if (this.activeMapTool === 'identifyPipe') {
                this.setMapTool('drawPoint');
            }
            this.cancelDraw();
        },
        clearGeometry() {
            this.cancelDraw();
            this.clearWhiteboardGeoms();
            this.clearIdBuffer();
            this.batchEdit = false;
        },
        isOriginalShape() {
            return this.version <= 1;
        },
        editPointIntersection() {
            this.$bvModal.show('modal-edit');
        },
        loadPipeIntersection() {
            this.$refs['risk-modal'].show();

            const queueService = 'Map Intersections';

            if (!this.batchEdit) {
                try {
                    this.setToastMessage({
                        queueService: queueService,
                        queueState: 'start',
                        queueMessage: 'started.',
                        downloadLink: '',
                        visible: false,
                    });
                    let data = {
                        user_id: this.UserId,
                        project_id: this.ProjectId,
                        geom: 'SRID=4326;' + this.drawnPolygon,
                    };

                    dreamsService
                        .GetLinksInPolygon(data)
                        .then((init_response) => {
                            setTimeout(() => {
                                this.setToastMessage({
                                    queueService: queueService,
                                    queueState: 'progress',
                                    queueMessage: 'in progress!',
                                    downloadLink: '',
                                    visible: true,
                                });
                            }, '1000');

                            const taskId = init_response.data.task_id;
                            this.startStatusCheckInterval(
                                taskId,
                                queueService,
                                3,
                                false
                            ); //3:loadPipeIntersection
                        });
                } catch (error) {
                    this.setToastMessage({
                        queueService: queueService,
                        queueState: 'error',
                        queueMessage: error.request,
                        downloadLink: '',
                        visible: true,
                    });
                }
                this.batchEdit = true;
            }
        },

        onCellSaved: function(args) {
            var changes = this.$refs.grid.ej2Instances.getBatchChanges();
            changes.changedRecords.forEach((change) => {
                this.addRiskAttribute({
                    GHDAssetId: change['GHDAssetId'],
                    'Modeled Material': change['Modeled Material'],
                    'Install Year (GIS)': change['Install Year (GIS)'],
                    'Modeled Diameter': change['Modeled Diameter'],
                    'Pipe Type': change['Pipe Type'],
                });
                const i = this.dataPipes.findIndex(
                    (_element) => _element.GHDAssetId === change.GHDAssetId
                );
                if (i > -1) {
                    this.dataPipes[i] = change;
                }
            });
        },

        //<SINGLE>
        identifyPipe() {
            if (this.activeMapTool === '') {
                this.setMapTool('identifyPipe');
            } else {
                this.clearMapTool();
            }
        },
        // gets the arrays of visible project and WMS layers
        getIdentifyIDs() {
            var mapLayers = [];
            var mapLayerIDs = [];
            this.mapLayers.forEach((mapLayer) => {
                // check that the layer is turned on and has a mapLayerID
                if (mapLayer.isChecked && mapLayer.mapLayerID) {
                    mapLayerIDs.push(mapLayer.mapLayerID);
                }
            });

            this.projLayers.forEach((mapLayer) => {
                let projectMapLayer = {
                    projectName: mapLayer.ProjectName,
                    projectLayers: mapLayer.ProjectLayers,
                    wmsLayers: [],
                };

                // check that the layer is turned on and has a mapLayerID by intersectoin
                let intersection = mapLayer.WMSLayers.filter((x) =>
                    mapLayerIDs.includes(x)
                );
                projectMapLayer.wmsLayers = intersection;

                mapLayers.push(projectMapLayer);
            });
            return mapLayers;
        },

        async handleMouseClickBuffer(lat, lng) {
            if (this.activeMapTool == 'identifyPipe') {
                this.clearHighlight();
                // define the geometry to use for the id buffer
                let bufferGeom = {
                    id: 'IdBuffer',
                    geometry: {
                        coordinates: [lat, lng],
                    },
                    styling: {
                        PolygonBorderColour: 'blue',
                        PolygonColour: 'light blue',
                        PolygonStyle: '10,10',
                        PolygonTransparency: 0,
                        SymbologySize: this.buffer,
                    },
                };
                this.clearIdBuffer();
                this.clearMapTool();
                this.addIdBuffer(bufferGeom);

                let selectPoint = `POINT (${lng} ${lat})`;
                let selection = 'SRID=4326;';
                selection += selectPoint;
                let data = {
                    user_id: this.UserId,
                    project_id: this.ProjectId,
                    geom: selection,
                    radius_of_buffer: this.buffer,
                };

                this.loadPipeAttributes(data);
                this.$bvModal.show('modal-edit');
            }
        },
        loadPipeAttributes(data) {
            const queueService = 'Map Intersection';
            try {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'start',
                    queueMessage: 'started.',
                    downloadLink: '',
                    visible: false,
                });

                dreamsService.GetLinksInPolygon(data).then((init_response) => {
                    setTimeout(() => {
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'progress',
                            queueMessage: 'in progress!',
                            downloadLink: '',
                            visible: true,
                        });
                    }, '1000');

                    const taskId = init_response.data.task_id;
                    this.startStatusCheckInterval(
                        taskId,
                        queueService,
                        4,
                        false
                    ); //4:loadPipeAttributes
                });
            } catch (error) {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'error',
                    queueMessage: error.request,
                    downloadLink: '',
                    visible: true,
                });
            }
        },

        attributeDisplayOnly(name) {
            return !(
                this.editableAttributes.includes(name) ||
                this.hiddenAttributes.includes(name)
            );
        },

        //update single attribute ; now it is in store dreams state.riskAttribute[]
        updateAttribute() {
            if (this.riskAttributes.GHDAssetId != '') {
                this.addRiskAttribute({
                    user_id: this.riskAttributes.user_id,
                    proj_id: this.riskAttributes.proj_id,
                    version: this.riskAttributes.version,
                    projected_year: this.riskAttributes.projected_year,
                    GHDAssetId: this.riskAttributes.GHDAssetId,
                    'Pipe Type': this.riskAttributes['Pipe Type'],
                    'Modeled Diameter': this.riskAttributes['Modeled Diameter'],
                    'Install Year (GIS)': this.riskAttributes[
                        'Install Year (GIS)'
                    ],
                    'Modeled Material': this.riskAttributes['Modeled Material'],
                });
            }
            this.$bvModal.hide('modal-edit');
        },

        createDataGrid: function() {
            Grid.Inject(Page, Sort);
            this.grid = new Grid({
                pageSettings: { pageSize: 50 },
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
                created: function() {
                    this.grid.showSpinner();
                    this.getList();
                }.bind(this),
                selectionSettings: { persistSelection: true },
            });
            this.grid.appendTo('#Grid');
        },

        getList: function() {
            this.grid.dataSource = this.dataPipes;
            var columns = [];
            if (this.setGridColumn) {
                Object.entries(this.riskAttributes).forEach((attribute) => {
                    const [key, value] = attribute;
                    if (this.editableAttributes.includes(key)) {
                        let editable = {
                            field: key,
                            headerText: key,
                            width: 250,
                            textAlign: 'Left',
                            allowEditing: 'true',
                        };
                        if (key === 'Modeled Material') {
                            editable.editType = 'dropdownedit';
                        } else {
                            editable.editType = 'numericedit';
                        }
                        columns.unshift(editable);
                    } else if (this.attributeDisplayOnly(key)) {
                        columns.push({
                            field: key,
                            headerText: key,
                            width: 200,
                            textAlign: 'Center',
                            allowEditing: 'false',
                        });
                    }
                });
                this.grid.columns = columns;
            }
        },

        latestVersion() {
            const queueService = 'Latest Version';
            try {
                dreamsService
                    .GetLatestVersion(this.UserId, this.ProjectId)
                    .then((init_response) => {
                        setTimeout(() => {}, '1000');

                        const taskId = init_response.data.task_id;
                        this.startStatusCheckInterval(
                            taskId,
                            queueService,
                            5,
                            false
                        ); //5:latestVersion
                    });
            } catch (error) {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'error',
                    queueMessage: error.request,
                    downloadLink: '',
                    visible: true,
                });
            }
        },

        setProjection(val) {
            let today = new Date();
            this.projectionYear = val == null ? 0 : val - today.getFullYear();
        },
    },

    created() {
        this.setModel('Risk');
        this.latestVersion();
        this.clearColorRange();
        this.setShowModel(false);
    },
    mounted() {},
    beforeDestroy() {
        this.clearColorRange();
        dreamsFunctions.clearMap();
        this.setModel('');
    },
    watch: {
        projectionYear(newProjectionYear, oldProjectionYear) {
            this.disableGenerateYear = false;
        },
        ProjectId(newVal, OldValue) {
            this.clearColorRange();
            dreamsFunctions.clearMap();
            this.clearDreams();
        },

        // when the coordinates are clicked, send the coordinates to the handler
        async clickCoords(newCoords) {
            if (this.activeMapTool == 'identifyPipe') {
                //identifyPipe drawPoint
                let [lat, lng] = spatialFunctions.validateCoordinates({
                    latlng: newCoords,
                });
                await this.handleMouseClickBuffer(lat, lng);
            }
        },
    },
    computed: {
        ...mapGetters('projects', {
            ProjectId: 'ProjectID',
        }),
        ...mapGetters('dreams', {
            version: 'version',
            riskRange: 'riskRange',
        }),
        ...mapState('dreams', {
            queueRiskAttribute: (state) => state.riskAttribute,
            riskModel: (state) => state.dreams.Risk,
            selectedLegendColor: (state) => state.showColorRange,
        }),
        ...mapState('oneMap', ['drawMarkers', 'bufferMarkers']),
        ...mapState('projects', {
            mapZoom: (state) => state.mapData.mapZoom,
        }),
        minProjection() {
            let today = new Date();
            return today.getFullYear();
        },
        maxProjection() {
            let today = new Date();
            return today.getFullYear() + 100;
        },
        projectedYear: {
            get() {
                let today = new Date();
                return today.getFullYear() + this.projectionYear;
            },
            set(val) {
                this.setProjection(val);
                return val;
            },
        },
        disableNoUpdateComputed() {
            return (
                this.queueRiskAttribute.length === 0 && this.disableGenerateYear
            );
        },
        UserId() {
            return this.$store.state.store.posts.UserID.toString();
        },
        hasDrawnPoint() {
            return this.bufferMarkers.length > 0;
        },
        hasDrawnPolygon() {
            let drawnComplete = false;
            if (this.drawPolygons.length > 0) {
                if (this.drawPolygons[0].geometry !== undefined) {
                    drawnComplete =
                        this.drawPolygons[0].geometry.coordinates[0].length > 2;
                }
            }
            return drawnComplete;
        },
        drawnPolygon() {
            let polygon = this.drawPolygons.find((obj) =>
                obj.id.startsWith('P')
            );
            if (polygon == null) {
                return '';
            } else {
                return spatialFunctions.convertToPgGeo(polygon);
            }
        },

        riskAttributes() {
            if (this.dataPipes[0] == undefined) {
                return {
                    GHDAssetId: '',
                    'Modeled Diameter': '',
                    'Install Year (GIS)': '',
                    'Modeled Material': '',
                    'Pipe Type': '',
                };
            } else {
                return this.dataPipes[0];
            }
        },
        buffer() {
            return 0.3 * Math.pow(2, 22 - this.mapZoom);
        },
    },
};
</script>
<style lang="scss" scoped>
.riskmodel-container {
    position: relative;
    float: left;
    height: calc(100vh - 126px);
    width: 100%;
    overflow: hidden;
    direction: ltr !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.layers-container {
    padding: 0 27px 0 19px;
    margin-top: 5px;
}
.header-container {
    position: relative;
    float: left;
    width: 100%;
    height: calc(100vh - 324px);
    overflow-y: scroll;
}
.content-container {
    position: relative;
    float: left;
    width: 100%;
    padding: 10px;
}
.footer-container {
    align-self: flex-end;
    float: right;
    width: 100%;
    min-height: 100px;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    text-align: center;
    justify-content: space-between;
    padding: 30px 10px;
}
.upload-formlabel {
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    color: #626262 !important;
    height: 100%;
    line-height: 2;
    font-family: var(--ffr);
    font-size: 16px;
    font-weight: normal;
    padding-left: 18px;
    text-align: left;
}
.radioButton {
    display: inline-block;
}
#radioRow {
    padding-bottom: 7px;
    display: flex;
    justify-content: space-around;
}

.tool-btns {
    padding-bottom: 10px;
}
.cancel-btns {
    flex-flow: row;
    margin-right: 20%;
}

.tool-btns {
    padding-bottom: 10px;
}
.tool-btn {
    margin-left: 20%;
    margin-top: 10px;
    display: block;
    width: 60%;
    color: white;
    border-radius: 3px;
    border: 0;
    height: 48px;
    padding: 11px 10px 11px 10px;
}
.btn-unselected {
    background-color: #006395;
}
.btn-selected {
    background-color: #00496d;
}
.btn-clear {
    border: 1px solid gray;
    height: 48px;
}
.btn-buffer {
    margin-left: 10px;
}

.modal_style {
    vertical-align: middle;
    margin: 200 100 !important;
    text-align: left;
    .riskgrid-container {
        max-height: 400px;
        overflow: auto;
    }
}
.riskgrid-footer {
    align-self: flex-end;
    float: right;
    justify-content: space-between;
}
.input-value {
    width: 70px;
}

.modal-displayrow {
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>
